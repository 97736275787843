body {
  background: #000;
  color: white;
  font-family: "montserrat";
}

body.signin-page {
  #page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.signin {
  padding: 200px 15px 200px;
  width: 100%;
  max-width: 456px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-wrapper {
    text-align: center;
    margin-bottom: 90px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 80px;

    .hyatt {
      font-size: 32px;
    }

    .ideas {
      font-size: 60px;
    }
  }

  .form-wrapper {
    background-color: #1d1d1d;
    border: 1px solid #414141;
    width: 100%;
    max-width: 420px;
    padding: 24px;
    position: relative;

    .form-inner {
      .form-title {
        text-align: center;
        margin-bottom: 26px;

        h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }

        p {
          font-size: 12px;
          color: #d2d2d2;
        }
      }
    }
  }

  .forgot,
  .back {
    font-size: 14px;
    color: #d2d2d2;
    margin-top: 16px;
    text-decoration: underline;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .forgot {
    margin-top: 40px;
  }
}

section {
  margin: auto;

  &.search {
    z-index: 10;
    position: relative;
  }

  .viewport {
    padding: 32px 15px;

    .inner {
      max-width: 1280px;
      margin: auto;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

#content {
  padding-top: 88px;

  @media (min-width: 1150px) {
    padding-top: 88px;
  }
}

.home-header {
  .viewport {
    @media (max-width: 1023px) {
      padding: 0;
    }
  }
  .viewport .inner {
    max-width: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
  }

  h2 {
    display: none !important;
    @media (min-width: 768px) {
      display: flex !important;
    }
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: #d2d2d2;
  }

  .img-wrapper {
    position: relative;

    img {
      position: relative;
      z-index: 0;
    }
  }

  .player-wrapper,
  .video-player {
    position: relative;
    width: 100%;
    background: #000000;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
    .video-home,
    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      width: 100% !important;
      height: 100% !important;
      pointer-events: all;
      .play {
        span {
          display: none;
        }
      }
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      gap: 16px;

      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      z-index: 6;

      text-decoration: none;

      @media (min-width: 1024px) {
        top: unset;
        bottom: 52px;
        left: 65px;
        transform: unset;

        padding: 12px 20px;
      }


      &.quiz {
        background: rgba(0, 0, 0, 0.8);
        border: 1px solid #FFFFFF;
        border-radius: 400px;

        padding: 4px 12px;

        @media (min-width: 1024px) {
          background: rgba(255, 255, 255, 0.2);
          border: none;
        }

        .text {
          display: block;

          font-weight: 700;
          font-size: 14px;
          line-height: 23px;

          color: #FFFFFF;

          @media (min-width: 1024px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .icon-quiz,
      .icon {
        height: 32px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 16px;

        .icon-playbuttonbold,
        .icon-playbutton {
          font-size: 32px;
        }
      }

      .icon-quiz {
        display: none;

        @media (min-width: 1024px) {
          display: block;
          height: 45px;
        }
      }

      .text {
        display: none;

        @media (min-width: 1024px) {
          display: block;

          font-weight: 600;
          font-size: 20px;
          line-height: 24px;

          color: #FFFFFF;
        }
      }
    }
  }

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .player-wrapper.mobile {
    padding: 8px;
    .media-container {
      position: relative;
      height: 100%;
      width: 100%;
      background: #000000;

      iframe {
        border-radius: 24px;
        overflow: hidden;
      }
    }

    .swiper-wrapper {
      position: absolute;
      top: 0;
    }

    .swiper-slide {
      background: #000000;
      overflow: hidden;

      border: 2px solid transparent;
      border-radius: 24px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #FF6600, #76BD87, #FF7DD7), linear-gradient(90deg, #FF6600, #76BD87, #FF7DD7);
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 0;
      bottom: 0;
      height: auto;
      transition: opacity 0.15s ease-in-out;
      margin-top: 0;

      &:after {
        font-family: "icomoon";
        color: white;
        font-weight: normal;
        z-index: 1;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: calc(50vw - 25px);
        pointer-events: none;
      }

      @media (min-width: 640px) {
        &:before {
          width: calc(33.333vw - 25px);
        }
      }
      @media (min-width: 768px) {
        &:before {
          width: calc(25vw - 25px);
        }
      }
      @media (min-width: 1024px) {
        &:before {
          width: calc(20vw - 25px);
        }
      }
      @media (min-width: 1320px) {
        &:before {
          width: 240px;
        }
      }
    }

    .swiper-button-prev {
      left: 0;
      &:after {
        content: "\e913";
        position: absolute;
        left: 17px;
        font-size: 16px;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        transform: rotate(-180deg);
      }

      @media (max-width: 640px) {
        &:before {
          left: -30vw;
        }
      }
    }

    .swiper-button-next {
      right: 0;

      &:after {
        content: "\e914";
        position: absolute;
        right: 17px;
        font-size: 16px;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }

    .swiper-button-disabled {
    }
  }

  .option-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px;
    &.mobile {
      padding-bottom: 0;
      .welcome > .category > div {
        padding-top: 4px;
      }

      .sustainability-tag-wrapper {
        background: rgba(126, 198, 37, 0.1);
        .text-sustainability {
          font-size: 10px;
        }
        .icon-sustainability {
          font-size: 12px;
        }
      }
    }

    .welcome {
      text-align: center;
      pointer-events: none;

      .fnb {
        color: #FF6600 !important;
      }
      .ope {
        color: #76BD87 !important;
      }
      .snm {
        color: #FF7DD7 !important;
      }

      .category__name {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
      }
    }

    .recommendation {
      display: flex;
      flex-direction: row;

      &.desktop {
        @media (max-width: 1023px) {
          display: none;
        }
      }

      h3 {
        display: none;
      }

      .categories {
        display: flex;
        flex-direction: row;
        border-left: 1px solid #D2D2D2;
        margin-left: 12px;

        .fnb {
          color: #FF6600 !important;
          background: #FF6600 !important;
        }
        .ope {
          color: #76BD87 !important;
          background: #76BD87 !important;
        }
        .snm {
          color: #FF7DD7 !important;
          background: #FF7DD7 !important;
        }

        .category {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.5);
          text-decoration: none;

          pointer-events: none;
          margin-left: 12px;

          &__name,
          .title {
            display: none;
          }

          &.active {
            &__name {
              font-size: 16px;
              line-height: 20px;
            }

            .title {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .viewport {
      padding: 24px;
      border-radius: 24px;

      .inner {
        overflow: hidden;

        border: 2px solid transparent;
        border-radius: 24px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #FF6600, #76BD87, #FF7DD7), linear-gradient(90deg, #FF6600, #76BD87, #FF7DD7);
      }
    }
    h1 {
      font-size: 58px;
      line-height: 58px;
    }

    h2 {
      font-size: 16px;
      line-height: 28px;
    }

    p {
      font-size: 14px;
      line-height: 23px;
    }

    .inner {
      display: flex;
    }

    .img-wrapper {
      width: 60%;
      float: right;
      display: flex;
      align-items: center;
    }

    .txt-wrapper {
      width: 40%;
      padding-right: 50px;
      padding-left: 64px;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }

    .player-wrapper {
      .cover {
        width: 100%;
        height: 100%;
        background: linear-gradient(81.24deg, #000000 3.51%, rgba(0, 0, 0, 0) 40.76%);
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        pointer-events: none;
      }
    }

    .option-wrapper {
      width: 50%;
      flex-direction: column;
      justify-content: start;
      background: #1F1F1F;
      padding: 0;

      @media (min-width: 1400px) {
        width: 40%;
      }

      .welcome {
        position: relative;
        padding: 28px 0 27px 0;
        margin: 0 20px;
        border-bottom: 2px solid #000000;

        @media (min-width: 1400px) {
          margin: 0 40px;
        }

        cursor: pointer;
        pointer-events: auto;

        img {
          height: 25px;
          object-fit: contain;
        }

        &.active {
          padding: 24px 0 22px 0;
          &::before {
            content: '';
            position: absolute;
            top: 30px;
            left: -30px;
            width: 24px;
            height: 24px;
            background: #1F1F1F;
            transform: rotate(-45deg);
            @media (min-width: 1400px) {
              left: -50px;
            }
          }
          img {
            height: 35px;
          }
        }
      }

      .recommendation {
        flex-direction: column;
        padding: 24px 18px;
        @media (min-width: 1400px) {
          padding: 24px 36px;
        }

        &.mobile {
          display: none;
        }

        h3 {
          display: block;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;

          text-transform: uppercase;

          color: #717171;
        }

        .categories {
          flex-direction: column;
          border-left: none;
          margin-top: 24px;
          margin-left: 0;

          .fnb,
          .ope,
          .snm {
            background: transparent !important;
          }

          .category {
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 0;
            background: transparent;
            margin-left: 0;

            cursor: pointer;
            pointer-events: auto;

            &:not(:last-of-type) {
              margin-bottom: 24px;
            }

            &__name {
              font-weight: 700;
              font-size: 11px;
              line-height: 13px;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .title {
              font-weight: 400;
              font-size: 14px;
              line-height: 23px;
              color: #FFFFFF;
              text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              transition: font-size 0.25s linear;
            }

            &.active {
              &:not(:last-of-type) {
                margin-bottom: 24px;
              }

              &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: -30px;
                width: 24px;
                height: 24px;
                background: #1F1F1F;
                transform: rotate(-45deg);
                @media (min-width: 1400px) {
                  left: -45px;
                }
              }

              .category__name {
                font-size: 16px;
                line-height: 20px;
                transition: font-size 0.25s linear;
              }

              .title {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
              }

              .category-wrapper {
                .text-sustainability {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 15px;
                  transition: font-size 0.25s linear;
                }
                .icon-sustainability {
                  font-size: 14px;
                  line-height: 14px;
                  transition: font-size 0.25s linear;
                }
              }
            }
          }
        }
      }
    }
  }
}

.quizz-mask {
  background: #0F0F0F;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.quizz-time {
  &.big{
    position: absolute;
    bottom: -55px;
    left: 50%;
    margin-left: -75px;
  
    img {
      margin: auto;
      width: 150px;
    }
  }
}

.quizz-category {
  font-size: 18px;
}

.quizz-title {
  font-weight: 700;
  font-size: 24px;
}

.quizz-tip {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 40px;
}

.questions-count {
  font-size: 14px;
}

.medal {
  display: inline-block;
  .passed-medal {
    display: flex;
    justify-content: center;
    padding: 8px;
    margin-top: 8px;
    font-size: 12px;
    background: #327228;
    border-radius: 4px;
    width: 85px;
    img {
      padding-right: 5px;
    }
  }
  .failed-medal {
    display: flex;
    justify-content: center;
    padding: 8px;
    margin-top: 8px;
    font-size: 12px;
    background: #FC434E;
    border-radius: 4px;
    width: 88px;
  }
}

.quizz-serie-episodes {
  background: #1F1F1F;
  border-radius: 8px;
  margin-top: 8px;
  padding: 24px 16px;

  .swiper-slide {
    width: 180px !important;
  }

  .video-wrapper {
    position: relative;
    .video {
      height: 89.5px;
      border-radius: 8px;
      overflow: hidden;

      background-position: center;
      background-size: cover;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      z-index: 1;

      .icon-playbuttonsmall {
        font-size: 36px;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .serie-infos {
    display: flex;
    justify-content: space-between;

    .serie-title {
      font-size: 16px;
      margin-bottom: 12px;
      font-weight: 600;
    }

    .serie-videos-count {
      font-weight: 400;
      font-size: 16px;
    }
  }
  
}

.quizz-page {
  .viewport {
    padding: 0 0 32px 0;

    .inner {
      max-width: 1440px;
    }

    @media (min-width: 1024px) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }

  .mobile-quizz-header {
    text-align: center;
    margin-top: 58px;
    .quizz-title {
      font-size: 20px;
    }
    .quizz-category {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      display: none;
    }
  }
}



.home-header-ideas {
  margin-bottom: 2rem;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;

  &__word {
    font-size: 10px !important;
    margin-bottom: 0.5rem;
    font-weight: 700;
    display: inline-block;
    //margin-left: 4px;

    &:nth-of-type(1) {
      color: #f8c400;
      margin-left: 0;
    }
    &:nth-of-type(2) {
      color: #35a8e0;
    }
    &:nth-of-type(3) {
      color: #ff7dd7;
    }
    &:nth-of-type(4) {
      color: #76bd87;
    }
    &:nth-of-type(5) {
      color: #ff6600;
    }
    // @media (min-width: 1024px) {
    //   margin-left: 4px;
    // }
  }
  @media (min-width: 1024px) {
    border-bottom: none !important;
  }
  &.for-quizz {
    margin-top: 0;
    text-align: center;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    border-bottom: none !important;
    justify-content: space-around;
    p {
      font-size: 12px !important;
      @media (min-width: 1024px) {
        font-size: 24px !important;
      }
    }
  }
}

.category-header,
.speaker-header,
.series-header {
  padding: 0;
  text-align: center;
  position: relative;
  .viewport {
    max-width: 100%;
    padding: 0;

    .inner {
      max-width: 100%;
    }
  }

  .txt-wrapper {
    h1 {
      font-size: 32px;
      text-transform: uppercase;
      line-height: 1.2;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
    }

    @media (min-width: 768px) {
      h1 {
        font-size: 52px;
        text-transform: uppercase;
        max-width: 650px;
        margin: auto;
      }
    }

    @media (min-width: 1200px) {
      h1 {
        font-size: 72px;
        max-width: 1200px;
      }
    }
  }

  .img-wrapper {
    position: relative;
    z-index: 0;
    .gradient {
      z-index: 1;
      pointer-events: none;
    }

    .medias {
      width: 100%;
      position: relative;
      height: 380px;
      z-index: 0;
      span {
        display: block;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }

      @media (min-width: 768px) {
        height: 502px;
      }
    }
  }
}

.speaker-header,
.series-header {
  .top {
    position: relative;
    .txt-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      padding: 16px;

      .author {
        font-family: "roboto";
        font-size: 18px;
        margin-top: 14px;
        margin-bottom: 14px;
      }

      .cat {
        font-size: 14px;
        margin-bottom: 14px;
      }

      @media (min-width: 768px) {
        padding: 36px;

        .author {
          font-family: "roboto";
          font-size: 24px;
          margin-top: 12px;
          margin-bottom: 12px;
        }

        .cat {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
    }

    .img-wrapper {
      .medias {
        @media (min-width: 768px) {
          height: 700px;
        }
        
        @media (min-width: 1872px) {
          height: 900px;
        }
      }
    }
  }
}

.category-header {
  .txt-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .logo {
      background-size: cover;
      background-position: center;
      width: 100px;
      height: 100px;
      margin: auto;
      margin-bottom: 16px;

      &.snm {
        background-image: url("/assets/images/logo-snm.png");
      }
      &.ope {
        background-image: url("/assets/images/logo-ope.png");
      }
      &.fnb {
        background-image: url("/assets/images/logo-fnb.png");
      }

      &.sustainability {
        background-image: url("/assets/images/logo-sustainability.png");
      }

      @media (min-width: 768px) {
        width: 150px;
        height: 150px;
        margin-bottom: 0px;
      }
    }
  }
}

.series-header {
  .back {
    position: absolute;
    z-index: 1;
    padding: 16px;
    a {
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      .icon-arrowup2 {
        text-transform: normal;
        margin-right: 10px;
        font-size: 20px;
        position: relative;
        top: 3px;
      }
    }
    @media (min-width: 768px) {
      padding-left: 64px;
    }
  }
  .description {
    padding: 0 15px;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }

    p {
      font-size: 12px;
      color: #d2d2d2;
    }

    @media (min-width: 768px) {
      max-width: 790px;
      margin: auto;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}

.profile-head {
  position: relative;

  .viewport {
    padding: 0;
    .inner {
      max-width: 100%;
    }
  }

  .medias {
    &:before {
      content: "";
      display: block;
      padding-top: 37.5%;
    }
    span {
      display: block;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
  @media (min-width: 768px) {
    .medias {
      &:before {
        padding-top: 17.36%;
      }
    }
  }
}

.infographics {
  // display: flex;
  .viewport {
    padding: 32px 20px;
    @media (max-width: 1023px) {
      padding-top: 0;
    }
    .inner {
      max-width: 1310px;
    }
  }

  .txt-wrapper {
    .home-header-ideas {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 4px 30px;
      border-bottom: none;

      margin-top: 36px;
      margin-bottom: 12px;

      &__word {
        font-weight: 700;
        font-size: 14px !important;
        line-height: 12px;
      }
    }

    p.description {
      font-size: 14px;
      line-height: 23px;
      color: #d2d2d2;
    }

    @media (min-width: 1024px) {
      .home-header-ideas {
        gap: 40px;

        &__word {
          font-weight: 600;
          font-size: 24px !important;
          line-height: 29px;
        }
      }

      p.description {
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        padding: 0 61px;
      }
    }
  }

  .item {
    padding: 8px 6px;
    width: 50%;
    float: left;
    //&:last-of-type {
    //  width: 100%;
    //  .info-inner {
    //    width: 50%;
    //    margin: auto;
    //  }
    //}
    @media (max-width: 767px) {
      &:nth-of-type(2n + 1) {
        padding-left: 0;
      }
      &:nth-of-type(2n) {
        padding-right: 0;
      }
    }

    .info-inner {
      background: #1f1f1f;
      border-radius: 6px;
      text-align: center;
      padding: 8px 18px;

      .num {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
      }

      .unit {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
    }

    @media (min-width: 768px) {
      width: 33.33%;
      //&:last-of-type {
      //  width: 20%;
      //  .info-inner {
      //    width: unset;
      //    margin: unset;
      //  }
      //}
    }

    @media (min-width: 1024px) {
      width: 16.66%;

      .info-inner {
        text-align: center;
        padding: 24px 27px;
      }
    }
  }
}

.series {
  .title-wrapper {
    display: flex;
    justify-content: center;

    .center-title {
      max-width: 700px;
      font-size: 50px;

      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  
  .tags {
    margin: auto;

    .tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      margin-bottom: 16px;
      gap: 12px;

      .tag-item {
        padding: 8px 16px;
        background-color: #1D1D1D;
        border-radius: 4px;
        gap: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-width: 1px;
        border-style: solid;
        border-color: #1D1D1D;
        cursor: pointer;
        transition: all 0.25s linear;

        @media (max-width: 1024px) {
          padding: 6px 12px;
        }
        
        @media (max-width: 950px) {
          padding: 4px 8px;
        }

        .icon {
          height: 20px;
        }

        &:hover {
          background-color: rgb(46, 46, 46);
        }
        
        &.essentials {
          // .tag-icon {
          //   // background-color: #FFEA32;
          // }

          .tag-text {
            color: #FFEA32;
          }
        }

        &.active {
          border-color: #D2D2D2;
        }

        &.essentials.active.essentials {
          border-color: #FFEA32;
        } 

        // .tag-icon {
        //   height: 24px;
        //   width: 24px;
        //   // background-color: #D2D2D2;
        // }

        .tag-text {
          font-weight: 500;
          font-size: 17px;
          color: #D2D2D2;

          @media (max-width: 1024px) {
            font-size: 14px;
          }

          @media (max-width: 950px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .controler-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .text-wrap {
      flex: 1;
      font-size: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      @media (max-width: 950px) {
        font-size: 12px;
      }

      .bold {
        font-weight: 600;
      }

      .see-all {
        // transition: all 0.25s linear;
        text-decoration: none;
        color: rgb(255, 255, 255);
        display: block;
        position: relative;
        padding: 1px 0;
        overflow: hidden;
        height: 30px;

        @media (max-width: 950px) {
          padding: 6px 0;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: rgba(255, 255, 255, .75);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;

          opacity: 1;
          transform: translate3d(-100%, 0, 0);
        }

        &:hover::after,
        &:focus::after {
          opacity: 1;
          transform: translate3d(0, 1px, 0);
          transform: translate3d(0, 0, 0);
        }

        &:hover {
          color: rgba(255, 255, 255, .75);
        }
      }
    }

    .button-wrap {
      flex-direction: row;
      display: flex;
      gap: 16px;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        height: 36px;
        width: 36px;
        border-radius: 18px;
        background-color: #1D1D1D;
        cursor: pointer;
        transition: all 0.25s linear;

        &.series-prev-btn {
          font-family: 'icomoon';

          &:after {
            content: "\e913";
          }
        }

        &.series-next-btn {
          font-family: 'icomoon';

          &:after {
            content: "\e914";
          }
        }



        &:hover {
          background-color: rgb(46, 46, 46);
        }

        &.swiper-button-disabled {
          cursor: not-allowed;
          background-color: rgba(29, 29, 29, .8);
          color: rgba(255, 255, 255, .5);

          &:hover {
            background-color: rgba(29, 29, 29, .8);
            color: rgba(255, 255, 255, .5);
          }
        }
      }
    }
  }

  .viewport {
    @media (max-width: 767px) {
      padding: 32px 24px;
    }
  }
  &.in-search-page {
    .viewport {
      padding-top: 0;
    }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 0;
    bottom: 0;
    height: auto;
    transition: opacity 0.15s ease-in-out;

    &:after {
      font-family: "icomoon";
      color: white;
      font-weight: normal;
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(50vw - 25px);
      pointer-events: none;
    }

    @media (min-width: 640px) {
      &:before {
        width: calc(33.333vw - 25px);
      }
    }
    @media (min-width: 768px) {
      &:before {
        width: calc(25vw - 25px);
      }
    }
    @media (min-width: 1024px) {
      &:before {
        width: calc(20vw - 25px);
      }
    }
    @media (min-width: 1320px) {
      &:before {
        width: 240px;
      }
    }
  }

  .swiper-button-prev {
    left: 0;
    &:after {
      content: "\e913";
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0px;
      background: rgba(0, 0, 0, 1);
      background: -moz-linear-gradient(
        left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 7%,
        rgba(0, 0, 0, 0) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(0, 0, 0, 1)),
        color-stop(7%, rgba(0, 0, 0, 1)),
        color-stop(100%, rgba(0, 0, 0, 0))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 7%,
        rgba(0, 0, 0, 0) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 7%,
        rgba(0, 0, 0, 0) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 7%,
        rgba(0, 0, 0, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 7%,
        rgba(0, 0, 0, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    }

    @media (max-width: 640px) {
      &:before {
        left: -30vw;
      }
    }
  }

  .swiper-button-next {
    right: 0;

    &:after {
      content: "\e914";
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0px;
      background: rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(7%, rgba(0, 0, 0, 0)),
        color-stop(95%, rgba(0, 0, 0, 1)),
        color-stop(100%, rgba(0, 0, 0, 1))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    }
  }

  .swiper-button-disabled {
  }

  .latest-list {
    .latest-serie {
      display: flex;
      flex-direction: column;
      background: #1F1F1F;

      margin-bottom: 24px;
    }

    .latest-serie-info {
      display: flex;
      flex-direction: column;

      padding: 24px 16px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .media-wrapper {
        position: relative;
        width: 100%;
        height: 148px;
        margin-bottom: 16px;

        @media (min-width: 768px) {
          width: 257px;
          margin: 8px 0;
        }

        .media {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          width: 100%;
          height: 148px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;

          border-radius: 8px;
        }
      }

      .text-wrapper {
        width: 100%;

        @media (min-width: 768px) {
          width: calc(100% - 257px - 16px);
        }

        .cat {
          font-size: 12px;
          line-height: 15px;
        }

        .title {
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;

          color: #FFFFFF;

          margin-top: 4px;
        }

        .author {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          color: #FFFFFF;

          margin-top: 4px;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;

          color: #FFFFFF;

          margin-top: 4px;
        }

        .links {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 24px;
          margin-top: 8px;

          .link {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            text-decoration: none;

            color: #2672EC;
          }

          .button {
            display: flex;
            background: #2672EC;
            border-radius: 6px;

            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            text-decoration: none;

            color: #FFFFFF;

            padding: 12px 38px;

            @media (min-width: 768px) {
              line-height: 20px;
              padding: 12px 24px;
            }

            .icon-playbuttonbold {
              font-size: 16px;
              margin-right: 10px;

              @media (min-width: 768px) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .latest-serie-episodes {
      padding: 24px 16px;

      .swiper-slide {
        width: 180px !important;
      }

      .video-wrapper {
        border-radius: 8px;
        position: relative;
        .video {
          height: 89px;
          border-radius: 8px;
          overflow: hidden;

          background-position: center;
          background-size: cover;
          position: 'relative',
        }

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 36px;
          height: 36px;

          background: rgba(0, 0, 0, 0.8);
          border-radius: 50%;
          z-index: 1;

          .icon-playbuttonsmall {
            font-size: 36px;
          }
        }
      }
    }

    .latest-serie-quiz {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      background: #414141;
      padding: 18px 24px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .text-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          height: 45px;
        }

        .text {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: center;

          color: #FFFFFF;
        }
      }

      .button {
        width: 100%;
        background: #1F1F1F;
        border-radius: 6px;

        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        text-transform: uppercase;
        text-decoration: none;
        text-align: center;

        color: #FFFFFF;

        padding-top: 8px;
        padding-bottom: 8px;

        @media (min-width: 768px) {
          width: auto;
          padding: 8px 32px;
        }
      }
    }
  }

  .series-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .slide-inner {
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    .star-bg {
      position: absolute;
      top: 14px;
      right: 14px;
      height: 30px;
      width: 30px;
      border-radius: 6px;
      background-color: #000;
      z-index: 1;

      .star-layer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(255, 234, 50);
        border-radius: 4px;
        transition: all 0.25s linear;

        & > .full {
          margin-top: -1px;
          display: none;
        }

        & > .notfull {
          margin-top: -1px;
          display: block;
        }
        
        &.selected {
          border-width: 0px;
          border-style: none;
          background-color: rgb(255, 234, 50);

          & .full {
            display: block;
          }

          & .notfull {
            display: none;
          }
        }
      }
    }

    .medias {
      position: relative;
      &:before {
        content: "";
        display: block;
        padding-top: 140%;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(0, 0, 0, 0)),
          color-stop(60%, rgba(0, 0, 0, 0)),
          color-stop(100%, rgba(0, 0, 0, 0.6))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
      }
      div {
        display: block;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }

    .text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      padding: 10px 15px;
      z-index: 1;

      h2 {
        font-size: 12px;
        text-transform: uppercase;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
        margin-bottom: 8px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .author {
        font-size: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .info {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        font-size: 10px;
        .cat {
          font-weight: 700;
        }
        .videos {
          margin-left: 4px;
        }

        @media (max-width: 768px) {
          span {
            display: block;
          }
        }
      }

      @media (min-width: 768px) {
        padding: 12px 16px;

        h2 {
          font-size: 22px;
          line-height: 1.25;
        }

        .author {
          font-size: 14px;
          margin: 15px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .info {
          font-size: 12px;
          .cat {
          }

          .videos {
          }
        }
      }
    }

    .hover-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      text-align: center;
      padding: 12px;
      z-index: 1;

      opacity: 0;
      transition: opacity 0.15s ease-in-out;

      &.fnb {
        background: linear-gradient(1.42deg, rgba(255, 102, 0, 0.6) 41.45%, rgba(255, 102, 0, 0.24) 77.52%, rgba(255, 102, 0, 0) 99.08%);
      }
      &.ope {
        background: linear-gradient(1.42deg, rgba(118, 189, 135, 0.6) 41.45%, rgba(118, 189, 135, 0.24) 77.52%, rgba(118, 189, 135, 0) 99.08%);
      }
      &.snm {
        background: linear-gradient(1.42deg, rgba(255, 125, 215, 0.6) 41.45%, rgba(255, 125, 215, 0.24) 77.52%, rgba(255, 125, 215, 0) 99.08%);
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;

        color: #FFFFFF;

        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);

        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 2;
      }

      .description {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #FFFFFF;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;

        margin-top: 8px;
      }
    }

    &:hover {
      .text {
        display: none;
      }
      .hover-content {
        opacity: 1;
      }
    }
  }
}

.hde {
  @media (min-width: 768px) {
    background-color: #1d1d1d;
  }

  .viewport {
    padding-left: 24px;
    padding-right: 24px;

    .inner {
      max-width: 1088px;
      background: #1D1D1D;
      padding: 40px 15px 32px 15px;
    }

    @media (min-width: 768px) {
      padding-top: 60px;
      padding-bottom: 60px;

      .inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        padding: 0;
        .left,
        .right {
          width: 50%;
        }
      }
    }
  }

  .left {
    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
    @media (min-width: 768px) {
      padding-right: 60px;
    }
    @media (min-width: 1024px) {
      padding-right: 160px;
    }
    h2 {
      font-size: 16px;
      margin-bottom: 16px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @media (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 16px;
      }
    }

    p {
      color: #d2d2d2;
      font-size: 12px;
      @media (min-width: 768px) {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }

  .right {
    .img-wrapper {
      display: block;
      img {
        display: block;
        width: 100%;
        // height: 100%;
      }
      @media (min-width: 768px) {
        display: none;
      }
    }

    .video-player {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }

      // .video-wrapper {

      //   video {
      //     width: 100%;
      //     display: block;
      //   }
      // }
    }
  }
}

.leaderboard {
  overflow: hidden;

  .center-title {
    margin-bottom: 32px;
  }

  .list {
    max-width: 480px;
    margin: 32px auto 40px;

    .title {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 18px;
      }

      span {
        font-size: 20px;
        margin-right: 7px;
        position: relative;
        top: 3px;
      }

      .icon-eye {
        color: #53c1ff;
      }

      .icon-thunder {
        color: #ffea32;
      }

      .icon-message {
        color: #9a92ff;
      }
    }

    .subtitle {
      color: #d2d2d2;
      text-align: center;
      font-size: 12px;
      margin: 12px 0 16px;
      height: 30px;
    }

    ul {
      padding-left: 36px;

      .first {
        margin-top: -3px;
        .medias {
          width: 24px;
          height: 24px;
          min-width: 24px;
        }

        .name {
          font-size: 14px;
        }

        @media (min-width: 768px) {
          .medias {
            width: 50px;
            height: 50px;
            min-width: 50px;
          }

          .name {
            font-size: 18px;
          }
        }
      }

      li {
        background: #1f1f1f;
        padding: 6px 8px;
        margin-bottom: 4px;
        position: relative;
        list-style: none;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 12px rgba(8, 39, 44, 0.6);
        border-radius: 6px;

        &:before {
          font-weight: 700;
          font-size: 16px;
          color: white;
          display: block;
          position: absolute;
          top: 50%;
          right: 100%;
          border-radius: 6px 0 0 6px;
          height: 42px;
          line-height: normal;
          margin: 0;
          top: 0;
          width: 38px;
          padding: 10px 8px;
          text-align: left;
          z-index: -1;
        }

        &.first {
          &:before {
            top: 5px;
            color: #1f1f1f;
          }
          .medias {
            height: 40px;
            width: 40px;
            min-width: 40px;
          }
        }

        &:nth-child(1):before {
          content: "#1";
        }
        &:nth-child(2):before {
          content: "#2";
        }
        &:nth-child(3):before {
          content: "#3";
        }
        &:nth-child(4):before {
          content: "#4";
        }
        &:nth-child(5):before {
          content: "#5";
        }

        .medias {
          width: 32px;
          height: 32px;
          min-width: 32px;
          position: relative;
          margin-right: 12px;
          z-index: 0;
          span {
            display: block;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            border-radius: 100%;
          }
        }

        .name {
          font-size: 12px;
          font-weight: 600;
          color: #dadada;
        }

        @media (min-width: 768px) {
          margin-bottom: 8px;
          .medias {
            width: 32px;
            height: 32px;
            min-width: 32px;
          }

          .name {
            font-size: 14px;
          }
        }
      }
    }

    &.expert ul li.first {
      border: 1px solid #53c1ff;
      &:before {
        background: #53c1ff;
      }
    }
    &.fastest ul li.first {
      border: 1px solid #ffea32;
      &:before {
        background: #ffea32;
      }
    }
    &.contrib ul li.first {
      border: 1px solid #9a92ff;
      &:before {
        background: #9a92ff;
      }
    }
  }

  @media (max-width: 768px) {
    .lead {
      overflow: visible;
      width: calc(100vw - 61px);

      .swiper-slide {
        .list {
          padding-right: 24px;

          .title,
          .subtitle {
            margin-right: -24px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .swiper-wrapper {
      display: block;
      transform: none !important;
    }

    .swiper-slide {
      width: auto !important;
    }

    .swiper-scrollbar {
      display: none;
    }
  }

  .swiper-scrollbar {
    width: 65.5%;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    .swiper-scrollbar-drag {
      background-color: #d2d2d2;
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #414141;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }

  @media (min-width: 768px) {
    .viewport {
      padding: 32px 0;
    }
    .list {
      float: left;
      width: 33.333%;
      margin: 0;
      padding: 15px;
    }
  }
}

.discover {
  .categories {
    clear: both;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .category {
      width: 33.33333%;
      float: left;
      text-align: center;
      position: relative;

      .img-wrapper {
        position: relative;
        img {
          display: block;
          position: relative;
          z-index: 0;
          transition: transform 0.8s ease-in-out;
        }

        .logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 41.4%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

          &:before {
            content: "";
            display: block;
            padding-top: 100%;
          }

          @media (min-width: 768px) {
            width: 35.37%;
          }
        }
      }

      &:active .img-wrapper img {
        transform: scale(1.1);
      }

      @media (min-width: 1024px) {
        &:active .img-wrapper img {
          transform: scale(1);
        }

        &:hover .img-wrapper img {
          transform: scale(1.1);
        }
      }

      &.fnb {
        .img-wrapper .logo {
          background-image: url("/assets/images/logo-fnb.png");
        }
      }

      &.ope {
        .img-wrapper .logo {
          background-image: url("/assets/images/logo-ope.png");
        }
      }

      &.snm {
        .img-wrapper .logo {
          background-image: url("/assets/images/logo-snm.png");
        }
      }

      .cat-title {
        font-size: 10px;
        font-weight: 700;
        margin-top: 8px;

        @media (min-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
}

.focus {
  .viewport {
    padding: 32px 42px;
    @media (min-width: 768px) {
      padding: 70px 20px;
    }
  }
  .sustainability-container {
    display: flex;
    justify-content: center;
    z-index: -1;
    max-height: 500px;
    overflow: hidden;
    #sustainability-video {
      cursor: pointer;
      width: 88%;

      @media (min-width: 768px) {
        width: 61%;
      }
      #sustainability-canvas {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}

.tags {
  width: 100%;
  max-width: 850px;
  margin: 32px auto;
  text-align: center;

  .tag {
    color: #717171;
    margin: 2px;
    padding: 2px 10px;
    border-radius: 30px;
    display: inline-block;
    font-family: "roboto";
    font-weight: 500;
    cursor: pointer;
    font-size: 13px;

    &.tag-ur-it {
      color: white;
      background-color: #2c2c2c;
    }

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}

.discover .tags {
  margin-top: -60px;
}

.series-videos {
  &.in-search-page {
    .viewport {
      padding-top: 0;
    }
  }
  .vid-count {
    color: #959595;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 14px;
      // margin-bottom: -10px;
    }
  }
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .video-item {
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 16px;
    }

    .inner {
      position: relative;

      &:hover {
        .video-wrapper {
          .hover-content {
            opacity: 1;
          }
        }
      }

      .video-wrapper {
        position: relative;
        // height: 200px;
        img {
          object-fit: cover;
        }

        .hover-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          text-align: center;
          padding: 12px;
          z-index: 1;

          opacity: 0;
          transition: opacity 0.15s ease-in-out;

          &.fnb {
            background: linear-gradient(1.42deg, rgba(255, 102, 0, 0.6) 41.45%, rgba(255, 102, 0, 0.24) 77.52%, rgba(255, 102, 0, 0) 99.08%);
          }
          &.ope {
            background: linear-gradient(1.42deg, rgba(118, 189, 135, 0.6) 41.45%, rgba(118, 189, 135, 0.24) 77.52%, rgba(118, 189, 135, 0) 99.08%);
          }
          &.snm {
            background: linear-gradient(1.42deg, rgba(255, 125, 215, 0.6) 41.45%, rgba(255, 125, 215, 0.24) 77.52%, rgba(255, 125, 215, 0) 99.08%);
          }

          .description {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;

            color: #FFFFFF;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;

            @media (min-width: 768px) {
              margin: 24px 62.5px;
            }
          }
        }
      }

      .infos {
        font-size: 10px;
        margin: 10px 0 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .right-info {
          display: flex;
          align-items: center;
        }

        span {
          margin-right: 6px;
        }
        @media (min-width: 640px) {
          font-size: 12px;
        }

        .done {
          float: right;

          &:before {
            content: "✓";
            display: inline-block;
            margin-right: 6px;
          }

          &.fnb {
            &:before {
              color: #ff6600;
            }
          }
          &.ope {
            &:before {
              color: #76bd87;
            }
          }
          &.snm {
            &:before {
              color: #ff7dd7;
            }
          }
        }
      }

      .left-info {
        float: right;
        .viewed {
        }

        span {
          margin-right: 0;
          margin-left: 6px;

          @media (min-width: 1024px) {
            margin-left: 15px;
          }

          span {
            margin-left: 0;
            margin-right: 4px;
          }
        }

        .like-count.active {
          color: #2672ec;
        }

        .viewed {
          span {
            color: #76bd87;
            margin-right: 4px;
          }
          &.fnb span {
            color: #ff6600;
          }
          &.snm span {
            color: #ff7dd7;
          }
        }
      }

      .author {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        color: #ffffff;
        margin-top: 10px;

        span {
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            color: #d2d2d2;
          }
        }

        span:nth-child(1) {
          text-decoration: none;
          font-weight: 400;
          cursor: default;
          &:hover {
            color: #fff;
          }
        }
      }

      .video-title {
        font-size: 700;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        @media (min-width: 640px) {
          font-size: 20px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0 -22px;
    .video-item {
      width: 33.3333%;
      float: left;

      .inner {
        padding: 0 22px;
        margin-bottom: 44px;
      }
    }
  }
}

.load-more {
  clear: both;
  text-align: center;
  margin: 40px 0;
}

.speakers {
  .viewport {
    @media (max-width: 767px) {
      padding: 32px 24px;
    }

    .inner {
      .title {
      }
    }
  }

  .speakers-list {
    margin: 0 -8px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .speaker-item {
      padding: 8px;
      max-width: 480px;
      margin: AUTO;

      .inner {
        background: #1f1f1f;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        padding: 8px;
        padding-left: 106px;
        .medias {
          width: 90px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          span {
            display: block;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
          }
        }

        .speaker-infos {
          .speaker-name {
            font-size: 16px;
            font-weight: 700;
          }

          .job,
          .branch {
            font-size: 10px;
            line-height: 1.2;
            color: #a5a5a5;
            font-family: "roboto";
            font-weight: 500;
          }

          .discover {
            font-family: "roboto";
            font-weight: 700;
            font-size: 14px;
            text-decoration: underline;
            color: white;

            &:hover {
              color: #d2d2d2;
            }
          }
        }
      }

      @media (min-width: 768px) {
        width: 33.3333%;
        float: left;
      }

      @media (min-width: 1024px) {
        width: 33.3333%;
        float: left;

        .inner {
          padding: 16px;
          padding-left: 166px;
          .medias {
            width: 150px;
          }

          .speaker-infos {
            height: 135px;
            .speaker-name {
              font-size: px;
              margin-bottom: 4px;
            }

            .job,
            .branch {
              font-size: 14px;
            }

            .discover {
              font-size: 16px;
              margin-top: 28px;
              display: inline-block;
              position: absolute;
              bottom: 15px;
            }
          }
        }
      }
    }
  }
}

body.series {
  #content {
    padding-top: 88px;
  }
}

body.video {
  #content {
    padding-top: 130px;
  }
}

.video-player {
  .viewport {
    padding-top: 0;

    .inner {
      max-width: 1080px;
    }
  }

  .video-wrapper {
    position: relative;
    margin: 0 -15px;

    @media (min-width: 768px) {
      margin: 0;
    }

    .play,
    .medias {
      opacity: 1;
      transition: opacity 0.15s ease-in-out;
    }

    &.playing {
      .play,
      .medias {
        opacity: 0;
      }
    }

    .play {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon-playbuttonsmall,
      .icon-playbutton {
        &:after {
          content: "";
          z-index: 0;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 100%;
        }

        &:before {
          position: relative;
          z-index: 1;
        }
      }

      .icon-playbuttonsmall {
        display: block;

        &:after {
          top: 0.3125vw;
          left: 0.3125vw;
          right: 0.3125vw;
          bottom: 0.3125vw;
        }

        &:before {
          font-size: 10vw;
        }

        @media (min-width: 768px) {
          display: none;
        }
      }

      .icon-playbutton {
        display: none;

        &:after {
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
        }

        &:before {
          font-size: 110px;
        }

        @media (min-width: 768px) {
          display: block;
        }
      }
    }

    .medias {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      span {
        display: block;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }

    video {
      position: relative;
      z-index: 0;
    }
  }

  .video-infos {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .right {
      font-size: 12px;
      font-family: "roboto";
      font-weight: 700;
      padding: 12px 0;
      margin-bottom: 12px;
      border-bottom: 1px solid #1c1c1c;

      .views {
        .view-count {
          margin-right: 16px;
        }

        .like-count {
          cursor: pointer;
          transition: color 0.15s ease-in-out;

          .icon-like {
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-right: 2px;
          }

          &:active {
            color: #2672ec;
          }
        }
      }

      @media (min-width: 768px) {
        border-bottom: none;
        font-size: 20px;
        padding: 20px 0;

        .views {
          .like-count {
            .icon-like {
              font-size: 20px;
              position: relative;
              top: 1px;
              margin-right: 2px;
            }

            &:hover {
              color: #2672ec;
            }
          }
        }
      }
    }

    .left {
      .video-title {
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 4px;
      }

      .video-category {
        font-size: 11px;
        margin-bottom: 4px;
        .cat {
          margin-right: 8px;
        }
      }

      .video-tags {
        margin-bottom: 4px;
        .tag {
          display: inline-block;
          font-size: 8px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 2px 8px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .speaker {
        font-size: 11px;
        font-weight: 300;
        margin-bottom: 4px;
        a {
          color: white;
          text-decoration: underline;
          font-weight: 500;

          &:hover {
            color: #d2d2d2;
          }
        }
      }

      .realease-date {
        font-size: 10px;
        font-weight: 300;
      }

      @media (min-width: 768px) {
        padding: 20px 0;
        .video-title {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .video-category {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .video-tags {
          margin-bottom: 8px;
          .tag {
            display: inline-block;
            font-size: 12px;
            border-radius: 5px;
            padding: 2px 8px;
          }
        }

        .speaker {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .realease-date {
          font-size: 14px;
        }
      }
    }
  }
}

.comments-videos {
  @media (min-width: 768px) {
    padding-top: 90px;
  }

  .viewport {
    .inner {
      max-width: 1080px;
    }

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }

  .bottom {
    @media (min-width: 768px) {
      .comments {
        width: 100%;
        margin-right: -340px;
        padding-right: 355px;
        float: left;
        position: relative;
        z-index: 0;
      }
      .other-videos {
        width: 340px;
        float: right;
        position: relative;
        z-index: 1;
      }
    }
    @media (min-width: 1024px) {
      .comments {
        width: 100%;
        margin-right: -440px;
        padding-right: 550px;
        float: left;
      }
      .other-videos {
        width: 440px;
        float: right;
      }
    }
  }

  .comments {
    .title {
      padding-bottom: 12px;
      border-bottom: 1px solid #353535;
    }

    .load-more {
      @media (min-width: 768px) {
        text-align: left;
      }

      @media (max-width: 768px) {
        .small {
          width: 100%;
        }
      }
    }
  }

  .comment-item-wrapper {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #353535;
    .load-more {
      text-align: left;
    }
  }

  .profile-pic {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;

    span {
      display: block;
      height: 100%;
      width: 100%;
      background-size: cover;
      border-radius: 100%;
    }

    @media (min-width: 1024px) {
      width: 34px;
      height: 34px;
    }
  }

  .comment-author {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    @media (min-width: 1024px) {
      font-size: 12px;
    }
    .expert-award i {
      color: #53c1ff;
    }
    .fastest-award i {
      color: #ffea32;
    }
    .contrib-award i {
      color: #9a92ff;
    }
    span {
      margin-left: 6px;
      i {
        margin-left: 3px;
      }
    }
  }

  .comment-itself {
    font-size: 12px;
    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }

  .comment-item-origin,
  .comment-item-reply,
  .comment-item-write {
    padding-left: 45px;
    position: relative;
    margin-bottom: 8px;

    p {
      margin-bottom: 10px;
    }
  }

  .comment-item-reply,
  .comment-item-write {
    background: #1f1f1f;
    margin-left: 40px;
    padding: 8px;
    padding-left: 50px;

    .profile-pic {
      top: 10px;
      left: 10px;
      span {
        position: absolute;
      }
    }
  }

  .comment-item-reply .comment-content a {
    text-decoration: none;
  }

  .comment-item-origin {
    margin-bottom: 0;

    .comment-content {
      & > a {
        text-decoration: none;
      }
    }
  }

  .like-reply {
    font-size: 14px;
    margin-right: 10px;
    .like-count {
      .icon-like {
        margin-right: 6px;
      }
    }

    a {
      font-weight: 700;
      color: white;

      &:hover {
        color: #d2d2d2;
      }
    }
  }

  .form-item {
    margin-bottom: 12px;
    textarea {
      display: block;
      padding-right: 195px;

      @media screen and (max-width: 1300px) {
        padding-right: 10px;
        padding-bottom: 40px;
      }
    }
  }

  .form-actions {
    text-align: left;
    margin-top: 0;
    .secondary {
      margin-left: 0;
    }
  }

  .video-wrapper {
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    .play {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 10vw;
      width: 10vw;
      z-index: 1;

      .icon-playbuttonsmall {
        display: block;

        &:after {
          content: "";
          display: block;
          position: absolute;
          border-radius: 100%;
          top: 0.3125vw;
          left: 0.3125vw;
          right: 0.3125vw;
          bottom: 0.3125vw;
          background-color: rgba(0, 0, 0, 0.8);
          z-index: 0;
        }

        &:before {
          font-size: 10vw;
          display: block;
          position: relative;
          z-index: 1;
        }
      }

      @media (min-width: 768px) {
        height: 32px;
        width: 32px;

        .icon-playbuttonsmall {
          &:after {
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
          }

          &:before {
            font-size: 32px;
          }
        }
      }
    }

    .medias {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;

      span {
        display: block;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
}

.back-to-top {
  text-align: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100px;
    margin: auto;
    cursor: pointer;
    span {
      display: inline-block;
    }

    .icon-arrowup {
      width: 42px;
      height: 42px;
      background-color: #1f1f1f;
      border-radius: 100%;
      margin-bottom: 10px;

      &:before {
        text-align: center;
        line-height: 42px;
        display: block;
        width: 100%;
      }
    }

    .text {
      font-family: Roboto;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.edit-profile {
  .viewport .inner {
    max-width: 424px;
  }
}

.profile-info {
  .viewport {
    .inner {
      max-width: 424px;
      text-align: center;

      @media (min-width: 768px) {
        max-width: 1280px;
      }
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }

  .info-wrapper {
    position: relative;
    @media (min-width: 768px) {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      // padding: 32px 0;
    }

    .profile-pic {
      position: relative;
      width: 120px;
      height: 120px;
      margin: auto auto 6px;

      @media (min-width: 768px) {
        width: 160px;
        height: 160px;
      }

      .medias {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(/assets/images/profile.png);
        background-size: cover;
        background-position: center;
        span {
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 100%;
          background-size: cover;
          background-position: center;
          background-position: center;
        }
      }

      //@media (min-width: 768px) {
      //  position: absolute;
      //  top: 32px;
      //  left: 0;
      //  margin: 0;
      //}
    }

    .profile-info {
      .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
      }

      .branch {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #d2d2d2;
        margin-bottom: 8px;
      }

      .stats {
        font-size: 12px;
        line-height: 1.7;
        margin-bottom: 30px;
        color: #717171;

        div {
        }
      }

      .edit {
        .tertiary {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          padding: 8px 24px;
        }

        @media (max-width: 768px) {
          .tertiary {
            width: 75%;
            span {
            }
          }
        }
      }

      @media (min-width: 768px) {
        padding-left: 25px;
        padding-right: 25px;
        position: relative;

        .name {
          font-size: 16px;
          margin-bottom: 2px;
        }

        .branch {
          font-size: 14px;
          margin-bottom: 12px;
        }

        .stats {
          div {
          }
        }
      }
    }
  }

  .badges-wrapper,
  .left,
  .right {
    padding: 32px 0;
    //border-bottom: 1px solid #1c1c1c;

    .edit {
      margin-top: 3rem;

      .tertiary {
        font-size: 12px;
      }

      @media (max-width: 768px) {
        .tertiary {
          width: 100%;
          span {
          }
        }
      }
    }

    &:nth-child(3) {
      margin-bottom: -32px;
    }

    @media (min-width: 768px) {
      //width: 33%;
      display: inline-block;
      vertical-align: top;
      //border-left: 1px solid #1c1c1c;
      //border-bottom: none;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @media (min-width: 768px) {
        font-size: 24px;
        text-align: left;
        margin-bottom: 24px;
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    .stat {
      font-size: 12px;
      text-align: left;
      color: #d2d2d2;
      //margin-bottom: 4px;

      &.expert .title span {
        color: #53c1ff;
      }
      &.fastest .title span {
        color: #ffea32;
      }
      &.contrib .title span {
        color: #9a92ff;
      }

      &.operation .num span,
      &.operation .title span {
        color: #76bd87;
        font-weight: 600;
      }
      &.fandb .num span,
      &.fandb .title span {
        color: #ff6600;
        font-weight: 600;
      }
      &.sandm .num span,
      &.sandm .title span {
        color: #ff7dd7;
        font-weight: 600;
      }

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        color: #FFFFFF;

        & + .title {
          margin-top: 8px;
        }

        .icon-eye,
        .icon-thunder,
        .icon-message {
          font-size: 16px;
          position: relative;
          top: 2px;
          margin-right: 4px;

          @media (min-width: 768px) {
            font-size: 24px;
          }
        }

        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          //margin-left: 8px;
        }
      }

      .progress-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        .progressBar {
          width: 60px;
          height: 60px;
        }

        .quizz-info {

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;

            color: #FFFFFF;
            margin: 0;

            strong {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;

              @media (min-width: 768px) {
                font-size: 20px;
                line-height: 24px;
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        font-size: 14px;
        color: #d2d2d2;
      }
    }
  }
  
  .badges-wrapper {
    width: 100%;

    h2.title {
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @media (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 12px;
      }
    }

    .stats {
      width: 60%;
      margin: auto;

      @media (min-width: 768px) {
        width: 50%;
      }
    }

    .stat {
      & + .stat {
        margin-top: 16px;

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }

      .title {
        .icon-eye, .icon-thunder, .icon-message {
          font-size: 32px;
        }

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;

          @media (min-width: 768px) {
            font-size: 28px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .quizzes-wrapper {
    h2.title {
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @media (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 12px;
      }
    }

    .edit {
      margin-bottom: 40px;
      @media (min-width: 768px) {
        margin-bottom: 48px;
      }

      .tertiary {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        padding: 6px 17px;

        @media (max-width: 768px) {
          width: 75%;
        }
      }
    }

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      text-align: left;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);

      margin-bottom: 32px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;

        margin-bottom: 36px;
      }
    }

    .left,
    .right {
      .stats {
        @media (min-width: 768px) {
          flex-direction: column;
        }

        .stat {
          width: 100%;
          margin-bottom: 40px;
        }
      }
    }

    .left {
      @media (min-width: 768px) {
        h3,
        .stats {
          margin-left: 30%;
        }
      }
    }

    .right {
      .stats {
        flex-direction: row;

        @media (min-width: 768px) {
          flex-direction: column;
          //padding-left: 20px;
        }
      }
    }
  }

  .left,
  .right {
    @media (min-width: 768px) {
      width: 50%;

      .stat {
        width: 30%;
      }
    }
  }

  .left {
    @media (min-width: 768px) {
      border-left: none;
      padding-right: 50px;
    }

    .stats.user {
      width: 60%;
      margin: auto;

      @media (min-width: 768px) {
        width: 100%;
      }

      .stat + .stat {
        margin-top: 16px;

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
        }
      }
    }
  }

  .right {
    @media (min-width: 768px) {
      padding-left: 64px;
      border-left: 1px solid #1c1c1c;
    }

    .stat {
      .num {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;

        color: #FFFFFF;

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 29px;
          margin-top: 32px;
        }
      }
    }

    .stats.user {
      flex-direction: row;
      justify-content: space-between;

      .stat {
        width: 30%;
      }
    }
  }
}

.statistics {
  .viewport {
    padding: 32px 24px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);

    margin-bottom: 40px;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  }

  .month-picker {
    position: relative;
    width: 100%;
    height: 48px;
    background: #1F1F1F;

    border: 1px solid #414141;
    border-radius: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #FFFFFF;

    padding: 16px 24px;

    @media (min-width: 768px) {
      width: 281px;
    }

    & > .rmp-container {
      position: relative;
      width: calc(100% + 48px);

      @media (min-width: 768px) {
        width: 281px;
      }

      .rmp-popup {
        position: absolute;
        top: 12px;
        left: -25px;
        width: 100%;

        padding: 0;
        background: #1F1F1F;
        border: 1px solid #414141;

        .rmp-pad {
          float: unset;
          margin: 0 auto;

          li.active,
          li.active:hover {
            background: #414141;
            color: #FFFFFF;
          }

          .rmp-btn:hover {
            background: #313131;
          }
        }
      }
    }

    .icon-chevronright2 {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);

      font-size: 24px;
    }
  }

  .data-wrapper {
    display: flex;
    flex-direction: column;

    margin: 40px 0;

    .data {
      position: relative;
      width: 60%;

      margin-bottom: 24px;

      @media (min-width: 768px) {
        width: 18%;
      }

      @media (min-width: 1400px) {
        width: 15%;
      }

      .num {
        font-weight: 700;
        font-size: 40px;
        line-height: 58px;

        text-transform: uppercase;

        color: #FFFFFF;

        @media (min-width: 768px) {
          font-size: 58px;
          line-height: 58px;
        }
      }

      .text {
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;

        text-transform: uppercase;

        color: #FFFFFF;

        margin-top: 8px;

        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 20px;

        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        color: #FFFFFF;

        .icon-arrowup,
        .icon-arrowup1 {
          color: #2672EC;
          margin-right: 9px;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .recharts-responsive-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .recharts-wrapper {
    background: #1D1D1D;
    border-radius: 8px;

    .recharts-legend-wrapper {
      top: 48px !important;

      @media (min-width: 768px) {
        top: 40px !important;
      }
    }

    .recharts-default-legend {
      display: flex;
      justify-content: flex-end;
    }

    .recharts-legend-item {
      display: flex !important;
      align-items: center;
      width: min-content;

      @media (min-width: 768px) {
        width: auto;
      }
    }

    .recharts-legend-item-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;

      color: #FFFFFF !important;
      
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .recharts-layer {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;

      text-align: center;

      color: #D2D2D2;
    }
  }

  .custom-tooltip {
    background: #414141;
    border-radius: 8px;
    padding: 15px;

    .label {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;

      color: #D2D2D2;

      &.blue {
        color: #53C1FF;
      }

      &.yellow {
        color: #FFEA32;
      }

      &.purple {
        color: #9A92FF;
      }

      & + .label {
        margin-top: 5px;
      }
    }
  }
}

.discover {
  .viewport {
    padding: 32px 20px;
    @media (min-width: 768px) {
      padding: 32px 42px;
    }
  }
}

.advertising-mob,
.advertising-desk {
  .wrapper {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
}

.advertising-desk {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.advertising-mob {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
  .viewport {
    .inner {
      max-width: 424px;
    }
  }
}

.my-lists {
  .viewport {
    @media (min-width: 1024px) {
      padding-top: 72px;
    }
  }

  .center-title {
    font-size: 38px;

    @media (min-width: 1024px) {
      font-size: 58px;
    }
  }
  
}

.watchlist-nav {
  margin: auto;
  text-align: center;
  margin-bottom: 18px !important;

  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    padding: 3px 10px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    width: 33%;
    padding: 24px 0px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .1em;

    @media (min-width: 1024px) {
      font-size: 24px;
    }

    &.active {
      border-bottom: 4px solid #2672EC;
      font-weight: 700;
    }
  }
}

.viewed-video-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .viewed-header {
    display: flex;
    justify-content: space-between;
  }
  .header-title {
    font-weight: 600;
    font-size: 20px;
    padding-left: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .header-clear {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    padding-right: 24px;
    padding-top: 17px;
    padding-bottom: 17px;
    cursor: pointer;
  }

  .viewed-video-block {
    width: 100%;
    @media (min-width: 768px) {
      width: 80%;
    }
    background: #1F1F1F;
    border-radius: 8px;
    margin-top: 24px;
  }

  .viewed-video-item {
    width: 100%;

    .viewed-block {
      position: relative;
      display: flex;
      padding: 12px 16px;
      border-top: 1px solid #414141;
      flex-direction: column;
      cursor: pointer;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .video-wrapper {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 200px;
        width: 100%;

        @media (min-width: 768px) {
          height: 100%;
          width: 100px;
        }

        border-radius: 8px;
        overflow: hidden;
        // IOS bug start
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        // IOS bug end
        img {
          object-fit: cover;
        }
      }

      .infos {
        width: 100%;        
        padding-top: 16px;
        padding-bottom: 8px;
        @media (min-width: 768px) {
          padding-left: 16px;
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .video-details {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          line-height: 18px;

          @media (min-width: 768px) {
            justify-content: flex-start;
            line-height: 23px;
          }

          .author {
            font-weight: 400;
            font-size: 14px;
            color: #D2D2D2;
            margin-left: 24px;
          }

          .time {
            font-weight: 500;
            font-size: 11px;
            color: #D2D2D2;
            margin-left: 24px;
          }

          .category {
            font-size: 12px;
          }
        }
  
        .video-title {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
        }
        
      }

      .video-description {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }

      .watch-again {
        align-items: center;
        justify-content: space-evenly;
        padding: 6px 8px;
        background: #414141;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        width: 121px;
        height: 32px;
        white-space: nowrap;
        margin-left: auto;
        display: flex;

        i {
          font-size: 12px;
          font-weight: 700;
          padding-right: 5px;
        }
      }

    }
  }

  @media (min-width: 768px) {
    margin: 0 -22px;
    .video-item {
      width: 33.3333%;
      float: left;

      .inner {
        padding: 0 22px;
        margin-bottom: 44px;
      }
    }
  }
}

.sustainability-search {
  margin-top: 110px;
  @media (min-width: 768px) {
    margin-top: 25px;
  }
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}

.sustainability-desc-wrapper {
  position: absolute;
  left: 50%;
  top: 83%;
  transform: translate(-50%, 0);
  z-index: 1;
  width: 90%;

  .solgan {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 auto;
    margin-bottom: 20px;
    max-height: 60px;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    @media (min-width: 768px) {
      font-size: 19px;
      line-height: 24px;
      max-width: 650px;
      margin-bottom: 24px;
      max-height: 48px;
    }
  
    @media (min-width: 1200px) {
      font-size: 22px;
      line-height: 27px;
      max-height: 54px;
      max-width: 1200px;
      white-space: nowrap;
      margin-bottom: 28px;
    }
  }

  .description {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #7EC625;
    letter-spacing: 0.1em;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
    max-width: 345px;
    margin: auto;
    max-height: 117px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      max-width: 650px;
      margin: auto;
    }
  
    @media (min-width: 1200px) {
      font-size: 32px;
      line-height: 39px;
      max-width: 1200px;
    }
  }
}

.category-wrapper {
  display: flex;
  align-items: center;

  .sustainability-tag-wrapper {
    display: inline-flex;
    align-items: center;
    background: rgba(126, 198, 37, 0.1);
    padding: 0px 4px;
  }
  
}

.series .slide-inner .text .info {
  .sustainability-tag-wrapper {
    display: inline-flex;
    align-items: center;
    margin-right: 0;
  }
}

.video-list .video-item .inner .infos {
  .sustainability-tag-wrapper {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    margin-right: 4px;
    background: rgba(126, 198, 37, 0.1);

    .icon-sustainability {
      margin-right: 0;
    }
    .text-sustainability {
      margin-right: 0;
    }
  }
}

.video-page .video-infos .left .video-tags {
  display: flex;
  .sustainability-tag-wrapper {
    border: 1px solid #7EC625;
    margin-left: 0;
    font-weight: 400;
    display: inline-flex;
    align-items: center;

    .text-sustainability {
      font-size: 8px;
      line-height: 1.5;
      margin-left: 4px;
      text-transform: capitalize;
  
      @media (min-width: 768px) {
        font-size: 12px;
      }
    }
  
    .icon-sustainability {
      font-size: 10px;
      @media (min-width: 768px) {
        font-size: 15px;
      }
    }
  }
}

.viewed-video-list .viewed-video-item .viewed-block .infos .video-details {
  .sustainability-tag-wrapper {
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    @media (min-width: 768px) {
      margin-left: 24px;
    }
  }
}

.tags > .tag {
  &.sustainability-tag-wrapper {
    padding: 2px 10px;
    text-transform: capitalize;
    color: #7EC625;
    line-height: 1.5;

    .text-sustainability {
      font-size: 13px;
      font-weight: 500;
      margin-left: 4px;
  
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  
    .icon-sustainability {
      font-size: 13px;
  
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.series-header .top .txt-wrapper .sustainability-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    .sustainability-tag-wrapper {
      background: rgba(126, 198, 37, 0.1);
    }
}

// common sustainability-tag css
.sustainability-tag-wrapper {
  padding: 2px;
  line-height: normal;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 600;
  text-transform: uppercase;
  color: #7EC625;
  display: inline-flex;
  align-items: center;

  .text-sustainability {
    font-size: 8px;
    margin-left: 4px;
    font-family: Roboto;

    @media (min-width: 768px) {
      font-size: 10px;
    }
  }

  .icon-sustainability {
    font-size: 10px;

    @media (min-width: 768px) {
      font-size: 12px;
    }
  }
}

.hyattfbphoto {
  .window-wrapper {
    border-radius: 16px;
    background-color: #1D1D1D;
    padding: 32px;
    position: relative;

    .items-wrapper {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .item {
        flex: 1;
        aspect-ratio: 1/1;
        background-color: #ccc;
        border-radius: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
        padding: 8px;
        background-size: cover;
        background-position: center;

        @media (max-width: 900px) {
          &:nth-child(3) {
            display: none;
          }
        }

        @media (max-width: 750px) {
          &:nth-child(4) {
            display: none;
          }
        }

        .tag-wrapper {
          padding: 4px 20px 4px 20px;
          background-color: rgba(44, 44, 44, 0.6);
          border-radius: 40px;

          @media (max-width: 1250px) {
            padding: 3px 15px 3px 15px;
          }
          @media (max-width: 900px) {
            padding: 2px 10px 2px 10px;
          }
          @media (max-width: 750px) {
            padding: 1px 5px 1px 5px;
          }

          .tag-text {
            color: #fff;
            font-size: 12px;
            font-weight: 600;

            @media (max-width: 1250px) {
              font-size: 12px;
            }
            @media (max-width: 900px) {
              font-size: 11px;
            }
            @media (max-width: 750px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .layer-mobile {
      display: none;
      position: absolute;
      background: linear-gradient(90deg, rgba(29,29,29,0) 1%, rgba(29,29,29,1) 85%, rgba(29,29,29,1) 100%);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 16px;

      @media (max-width: 900px) {
        display: block;
      }
    }

    .info-wrapper {
      position: absolute;
      background: linear-gradient(90deg, rgba(29,29,29,0) 1%, rgba(29,29,29,1) 100%);
      top: 32px;
      bottom: 32px;
      right: 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 8px;
      z-index: 1;
      min-width: 75%;

      .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .hyatt-logo {
          height: 42px;
          margin-bottom: 6px;
        }

        .classic-logo {
          height: 45px;
        }

        @media (max-width: 900px) {
          .hyatt-logo {
            height: 20px;
          }

          .classic-logo {
            height: 20px;
            margin-bottom: 6px;
          }
        }

        .title {
          font-size: 50px;
          font-weight: 700;
          transition: all 0.3s ease-in-out;
          line-height: 1.2;
          letter-spacing: 5px;

          &.orange {
            color: #FF6600;
          }

          // @media (max-width: 1250px) {
          //   font-size: 32px;
          // }
          @media (max-width: 900px) {
            font-size: 39px;
          }
          // @media (max-width: 750px) {
          //   font-size: 19px;
          // }
        }
      }

      .button {
        border-width: 1px;
        border-radius: 8px;
        border-color: #fff;
        border-style: solid;
        padding: 8px 32px 8px 32px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        margin-top: 12px;

        @media (max-width: 900px) {
          margin-top: 0px;
          padding: 6px 24px 6px 24px;
        }
        @media (max-width: 650px) {
          padding: 4px 19px 4px 19px;
        }
        // @media (max-width: 750px) {
        //   padding: 2px 15px 2px 15px;
        // }

        &:hover {
          background-color: #fff;
          color: #1D1D1D;
        }

        .text {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;

          @media (max-width: 900px) {
            font-size: 12px;
          }
          @media (max-width: 650px) {
            font-size: 10px;
          }
          // @media (max-width: 750px) {
          //   font-size: 9px;
          // }
        }
      }

      @media (max-width: 900px) {
        margin-bottom: 16px;
        position: relative;
        top: unset;
        bottom: unset;
        right: unset;
        align-items: flex-start;

        .title-wrapper {
          align-items: flex-start;
          gap: 2px;

          .title {
            font-size: 16px;
          }
        }

        .button {
          padding: 6px 24px 6px 24px;

          .text {
            font-size: 16px;
          }
        }
      }
    }
  }
}