@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(120deg);
	}
	to {
		transform: rotate(120deg);
	}
}

@keyframes pulse {
	from {
		opacity: 1;
	}
	20% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideInLeft {
	from {
		left: -700px
	}
	to {
		left: 0px;
	}
}

@keyframes slideOutRight {
	from {
		left: 0px
	}
	to {
		left: -700px;
	}
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: white;
    border-radius: 10px;
    display: inline-block;
    height: 15px;
    width: 15px;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}